import styled from '@emotion/styled'

const Header = styled.h1`
  font-size: 2rem;
  font-weight: normal;
  text-align: ${({ align }) => align || 'inherit'};
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: ${({ bottom }) => bottom || '1rem'};
`

const Label = styled.h6`
  opacity: 0.6;
  text-align: ${props => (props.align ? props.align : 'inherit')};
  font-weight: bolder;
  font-size: 0.7rem;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0.75em;
`

export { Header, Label }
