import { keyframes } from '@emotion/core'
import { useEffect, useState } from 'react'
import { capitalize } from 'lodash'

export const fadeIn = keyframes`
from {
  transform: translateY(10px);
  opacity: 0;
}
to {
  transform: translateY(0);
  opacity: 1;
}
`

export const fadeOut = keyframes`
from {
  transform: translateY(0);
  opacity: 1;
}
to {
  transform: translateY(10px);
  opacity: 0;
}
`

/**
 * MEDIA QUERIES
 */
const breakpoints = [600, 768, 992, 1200]
export const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`)

/**
 * Mini time converter
 */
export const isNullorEmpty = obj => {
  return !obj || !Object.keys(obj).length
}

export const secondsToMinute = s => {
  const m = ~~(s / 60)
  let ss = s % 60
  if (ss.toString().length === 1) ss += '0'
  return `${m}:${ss}`
}

/**
 * JS MEDIA QUERIES
 */
export const useMedia = size => {
  const [isMobileSize, setMobileSize] = useState(false)
  useEffect(() => {
    if (typeof window === 'undefined') {
      setMobileSize(false)
    } else {
      const isMobile = window.matchMedia(`(max-width: ${size}px)`).matches
      setMobileSize(isMobile)
    }
  }, [])
  return isMobileSize
}

/**
 * get page name
 */

export const getPageName = path => capitalize(path.replace(/\//g, ''))
