import React from 'react'
import { css } from '@emotion/core'
import { Links, StyledLink } from './links'
import { Logo } from './logo'
import { SocialMedia } from './social'
import { mq } from '../utils'
import MobileNav from './mobile'
import HomeMobileLinks from './home-mobile'

const navStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 7rem;
  margin: 0;
  line-height: 7rem;
  display: flex;
  z-index: 1000;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));

  & > div:first-of-type,
  & > div:last-of-type {
    flex: 1 1;
  }

  & > div:nth-of-type(2) {
    flex: 2 2;
  }

  ${mq[0]} {
    display: none;
  }
`

const NavLayout = ({ left, center, right }) => (
  <nav css={navStyle}>
    <div>{left}</div>
    <div>{center}</div>
    <div>{right}</div>
  </nav>
)

const DefaultLinks = () => (
  <Links>
    <StyledLink to="/work">Work</StyledLink>
    <StyledLink to="/contact">Contact</StyledLink>
    <StyledLink to="/company">Company</StyledLink>
    <SocialMedia />
  </Links>
)

const Nav = () => <NavLayout center={<DefaultLinks />} />

const NavWithLogo = ({ children }) => (
  <NavLayout
    left={<Logo to="/">Wonderboys</Logo>}
    center={<DefaultLinks />}
    right={children}
  />
)

export { Nav, NavWithLogo, MobileNav, HomeMobileLinks, SocialMedia }
