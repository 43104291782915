import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { fadeIn } from '../utils'
import FadeInImage from '../fadeInImage'

// import { secondsToMinute } from '../utils'

const Info = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #fff;
  padding: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 100;

  h1,
  h2,
  h3,
  span {
    font-size: 1rem;
    line-height: 1.25;
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  h2 {
    font-weight: bold;
  }
`

const Cell = ({ data, play, contentId, slotId }) => {
  if (contentId == null)
    return (
      <div
        css={css`
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 0.2);
        `}
      >
        No Content
      </div>
    )

  const { preview, file, client, title, year } = data[contentId].node
  const poster = preview.mq
  const source = file.hq
  return (
    <div
      onClick={() => {
        play({ source, year, title, poster })
      }}
      css={css`
        color: white;
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.2) center/cover no-repeat;
        overflow: hidden;
        animation: ${fadeIn} 0.3s ease-out;
        animation-fill-mode: both;
        animation-delay: ${slotId / 30}s;

        &:hover img {
          transform: scale(1.05);
        }

        &:hover > .info-hover {
          transform: translateX(0);
          opacity: 1;
        }
      `}
    >
      <Info className="info-hover">
        <h2>{client}</h2>
        <h1>{title}</h1>
        <h3>{year}</h3>
      </Info>
      <FadeInImage
        key={poster}
        src={poster}
        alt={`${client} - ${title} - ${year}`}
      />
    </div>
  )
}

export default Cell
