import React from 'react'
import SEO from './seo'

import './layout.css'

const Layout = ({ children, ...rest }) => (
  <>
    <SEO {...rest} />
    {children}
  </>
)

export default Layout
