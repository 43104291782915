import React from 'react'
import { css } from '@emotion/core'
import { baseClasses } from '../../styles'
import throttle from 'lodash/throttle'
import { Label, Header } from '../../typography'
import { OutlineButton } from '../../button'

const FilterCell = ({ selected, name, item, onChange, onReset }) => {
  const { value, label } = item
  const checked = selected ? selected.value === value : false
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <input
        css={css`
          width: 0;
          height: 0;
          visibility: hidden;
          &:hover + label,
          &:focus + label {
            border: 1px solid rgba(255, 255, 255, 0.4);
          }
          &:checked + label {
            opacity: 0.4;
          }
        `}
        onChange={() => onChange({ name, value, label })}
        checked={checked}
        value={value}
        id={`id-${name}-${value}`}
        type="radio"
        name={name}
      />
      <label
        css={css`
          position: relative;
          display: inline-block;
          padding: 0.3em 0.5em;
          margin-left: 1.5rem;
          border: 1px solid rgba(255, 255, 255, 0);
          transition: all 0.3s ease;
          z-index: 11;
        `}
        htmlFor={`id-${name}-${value}`}
      >
        {label}
      </label>
      <div
        css={css`
          position: absolute;
          top: 50%;
          left: 0;
          width: 1.5rem;
          height: 1.5rem;
          line-height: 1;
          border: 1px solid rgba(255, 255, 255, 0.4);
          text-align: center;
          color: #fff;
          border-radius: 0.75rem;
          transform: translate(${checked ? '0' : '20%'}, -50%);
          opacity: ${checked ? 1 : 0};
          transition: all 0.3s ease;
          font-size: 1.25rem;
          z-index: 10;
        `}
        onClick={onReset}
      >
        ×
      </div>
    </div>
  )
}

class ScrollableColumn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      top: true,
    }

    this.ref = React.createRef()

    this.scrollHandler = throttle(this.scrollHandler.bind(this), 100, {
      leading: true,
    })
  }

  componentDidMount() {
    const $el = this.ref.current
    $el.addEventListener('scroll', this.scrollHandler)
  }

  componentWillUnmount() {
    const $el = this.ref.current
    $el.removeEventListener('scroll', this.scrollHandler)
  }

  scrollHandler(e) {
    if (e.target.scrollTop < 10) this.setState({ top: true })
    else this.setState({ top: false })
  }

  render() {
    const { as, children } = this.props
    const { top } = this.state
    const Tag = `${as}`
    return (
      <div
        css={css`
          position: relative;
          width: 125%;
        `}
      >
        <div
          css={css`
            position: absolute;
            width: 100%;
            top: 0;
            height: 6rem;
            pointer-events: none;
            opacity: ${top ? 0 : 1};
            transition: opacity 0.3s ease;
            background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
            z-index: 100;
          `}
        />
        <Tag
          ref={this.ref}
          css={css`
            position: relative;
            padding: 0.5rem 0;
            height: 48vh;
            overflow-x: visible;
            overflow-y: scroll;
          `}
        >
          {children}
        </Tag>
        <div
          css={css`
            position: absolute;
            width: 100%;
            bottom: 0;
            height: 6rem;
            pointer-events: none;
            opacity: ${top ? 1 : 0};
            transition: opacity 0.3s ease;
            background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
            z-index: 100;
          `}
        />
      </div>
    )
  }
}

ScrollableColumn.defaultProps = {
  as: 'div',
}

class FilterColumn extends React.Component {
  // shouldComponentUpdate(nextProps) {
  // const { selected } = this.props
  // return selected.value !== nextProps.selected.value
  // }
  render() {
    const { selected, label, name, items, onChange, onReset } = this.props
    return (
      <div
        css={css`
          position: relative;
        `}
      >
        <Label
          css={css`
            margin-left: 2rem;
          `}
        >
          {label}
        </Label>
        <ScrollableColumn as="ul">
          {items.map(
            (item, i) =>
              item && (
                <li key={i}>
                  <FilterCell
                    onReset={onReset}
                    selected={selected}
                    onChange={onChange}
                    name={name}
                    item={item}
                  />
                </li>
              )
          )}
        </ScrollableColumn>
      </div>
    )
  }
}

const FilterPanel = ({ searchies, togglePanel, query, onReset, onChange }) => {
  const { clients, year, categories } = searchies
  return (
    <div
      css={css`
        position: fixed;
        z-index: 900;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: #000;
        color: #fff;
      `}
    >
      <div
        css={[
          baseClasses.container,
          css`
            margin-top: 7rem;
          `,
        ]}
      >
        <Header align="center">Filter</Header>
        <ul
          css={css`
            margin: 0;
            margin-left: -2rem;
            display: flex;
            justify-content: space-around;
            width: 100%;
            padding: 1rem 0;
          `}
        >
          <li
            css={css`
              flex: 0 1 auto;
            `}
          >
            <FilterColumn
              selected={query.categories}
              onReset={() => onReset('categories')}
              onChange={onChange}
              label={'Industries'}
              name={'categories'}
              items={categories}
            />
          </li>
          <li
            css={css`
              flex: 0 1 auto;
            `}
          >
            <FilterColumn
              selected={query.clients}
              onReset={() => onReset('clients')}
              onChange={onChange}
              label={'Clients'}
              name={'clients'}
              items={clients}
            />
          </li>
          <li
            css={css`
              flex: 0 1 auto;
            `}
          >
            <FilterColumn
              selected={query.year}
              onReset={() => onReset('year')}
              onChange={onChange}
              label={'Year'}
              name={'year'}
              items={year}
            />
          </li>
        </ul>
      </div>
    </div>
  )
}

const FilterBar = ({
  isOpened,
  searchies,
  onReset,
  onChange,
  togglePanel,
  categories,
  clients,
  year,
  onResetAll,
  handleMainButton,
  renderMessage,
}) => (
  <div>
    {isOpened && (
      <FilterPanel
        searchies={searchies}
        onReset={onReset}
        onChange={onChange}
        togglePanel={togglePanel}
        query={{ categories, clients, year }}
      />
    )}
    <div
      css={css`
        position: fixed;
        z-index: 1000;
        width: 100%;
        height: 7rem;
        bottom: 0;
        background: linear-gradient(
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.6) 70%
        );
      `}
    >
      <div
        css={css`
          position: absolute;
          width: 60%;
          display: block;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      >
        {isOpened && (
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 50%;
              width: 100%;
              text-align: center;
              transform: translate(-50%, -140%);

              & > button {
                border: none;
                display: inline-block;
                padding: 0 1rem;
                background: transparent;
                font-size: 0.75rem;
                color: #fff;
              }

              & > button:first-of-type {
                border-right: 1px solid rgba(255, 255, 255, 0.25);
              }
            `}
          >
            <button onClick={onResetAll}>Clear All</button>
            <button onClick={togglePanel}>Close</button>
          </div>
        )}
        <OutlineButton onClick={handleMainButton} fluid size={1}>
          {renderMessage()}
        </OutlineButton>
      </div>
    </div>
  </div>
)

export { FilterBar }
