import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import Map from './map'
import { Label } from '../typography'
import { mq } from '../utils'

const LocationLayout = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  & .location-address {
    flex: 2 2 50%;
    padding-right: 1rem;
  }

  & .location-phone {
    flex: 1 1 25%;
    padding-right: 1rem;
  }

  & .location-fax {
    flex: 1 1 25%;
  }

  ${mq[0]} {
    flex-wrap: wrap;
    .location-address {
      flex: 0 1 100%;
      padding: 0;
    }

    .location-phone {
      flex: 0 1 66.6%;
      padding-right: 1rem;
    }

    .location-fax {
      flex: 0 1 33.3%;
    }
  }
`

const Location = () => (
  <StaticQuery
    query={graphql`
      query {
        contact {
          phone
          address
          fax
        }
      }
    `}
    render={data => {
      const { address, phone, fax } = data.contact
      return (
        <>
          <Map />
          <LocationLayout>
            <div className="location-address">
              <Label as="h2">Address</Label>
              <p>{address}</p>
            </div>
            <div className="location-phone">
              <Label as="h2">Phone</Label>
              <p>{phone}</p>
            </div>
            <div className="location-fax">
              <Label as="h2">Fax</Label>
              <p>{fax}</p>
            </div>
          </LocationLayout>
        </>
      )
    }}
  />
)

export default Location
