import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Modal from '../modal'
import { Icon } from '../icon'
import Divider from '../divider'
import { ReactComponent as IconMenu } from '../../images/svg/baseline-menu-24.svg'
import { ReactComponent as IconClose } from '../../images/svg/ic-close-24.svg'
import { SocialMedia } from './social'
import { fadeIn } from '../utils'

const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  padding: 1rem;
  width: 100%;
  height: 4rem;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`

const IconContainer = styled.button`
  display: block;
  flex: 1 1 25%;
  background: none;
  padding: 1rem 0;
  text-align: ${({ align }) => align || 'inherit'};
  border: none;
  color: #fff;
`

const PageTitle = styled.h1`
  flex: 2 2 50%;
  background: none;
  margin: 0;
  text-align: center;
  padding: 0.5rem;
  font-size: 1.5rem;
`

const StyledOverlayMenu = styled.div`
  position: absolute;
  top: 10vh;
  left: 2rem;
  right: 2rem;
  text-align: center;
`

const Logo = styled(Link)`
  display: inline-block;
  color: #fff;
  position: relative;
  font-size: 1.5rem;
  width: 13rem;
  height: 3rem;
  text-indent: -9999px;
  margin-bottom: 2rem;
  background: url('/logo-normal.svg') center/contain no-repeat;
  animation: ${fadeIn} 0.3s ease-out;
  animation-fill-mode: both;
  animation-delay: ${({ order = 0 }) => order * 0.1}s;
`

const StyledLink = styled(Link)`
  display: block;
  color: #fff;
  line-height: 1.4;
  text-decoration: none;
  font-size: 2.5rem;
  animation: ${fadeIn} 0.3s ease-out;
  animation-fill-mode: both;
  animation-delay: ${({ order = 0 }) => order * 0.1}s;
`

const MenuContainer = styled.div`
  margin: 2rem 0;
  animation: ${fadeIn} 0.3s ease-out;
  animation-fill-mode: both;
  animation-delay: ${({ order = 0 }) => order * 0.1}s;
`

const CloseButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  margin: 0 auto;
  padding: 0;
  border: none;
  background: none;
  display: block;
  animation: ${fadeIn} 0.3s ease-out;
  animation-fill-mode: both;
  animation-delay: ${({ order = 0 }) => order * 0.1}s;
`

const OverlayMenu = () => (
  <StyledOverlayMenu>
    <Logo order={0} />
    <MenuContainer>
      <StyledLink order={1} to="/work">
        Work
      </StyledLink>
      <StyledLink order={2} to="/contact">
        Contact
      </StyledLink>
      <StyledLink order={3} to="/company">
        Company
      </StyledLink>
    </MenuContainer>
    <Divider />
    <MenuContainer order={4}>
      <SocialMedia size="2" />
    </MenuContainer>
    <CloseButton order={5}>
      <Icon size="2" icon={<IconClose />} />
    </CloseButton>
  </StyledOverlayMenu>
)

class Menu extends React.Component {
  state = {
    isOpen: false,
  }

  toggle = isOpen => {
    this.setState(state => {
      const _isOpen = isOpen === undefined ? !state.isOpen : isOpen
      return {
        isOpen: _isOpen,
      }
    })
  }

  handleClick = () => {
    this.toggle(true)
  }

  render() {
    const { isOpen } = this.state
    return (
      <>
        <IconContainer align="left" onClick={this.handleClick}>
          <Icon icon={<IconMenu />} />
        </IconContainer>
        <Modal isOpen={isOpen} close={this.toggle}>
          <OverlayMenu />
        </Modal>
      </>
    )
  }
}

const MobileNav = ({ title = 'Wonderboys', children }) => (
  <StyledNav>
    <Menu />
    <PageTitle>{title}</PageTitle>
    {children || <IconContainer />}
  </StyledNav>
)

export { IconContainer, StyledNav, PageTitle }

export default MobileNav
