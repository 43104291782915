import React from 'react'
import { css } from '@emotion/core'
import { mq } from '../utils'
import { Icon } from '../icon'
import { ReactComponent as IconClose } from '../../images/svg/ic-close-24.svg'
import Modal from '../modal'
// import objectFitPolyfill from 'objectFitPolyfill'

class Player extends React.Component {
  ref = React.createRef()

  render() {
    const { close, content } = this.props
    return (
      <>
        <button
          css={css`
            display: inline-block;
            background: none;
            border: none;
            width: 2.5rem;
            height: 2.5rem;
            position: absolute;
            z-index: 1105;
            top: 2rem;
            right: 2rem;

            ${mq[0]} {
              top: auto;
              right: auto;
              left: 50%;
              bottom: 3rem;
              transform: translateX(-50%);
            }
          `}
          onClick={close}
        >
          <Icon icon={<IconClose />} />
        </button>
        <div
          css={css`
            position: absolute;
            top: 2rem;
            left: 1.5rem;
            z-index: 1105;
            margin-right: 2rem;

            ${mq[0]} {
              left: 1rem;
            }

            & > h1,
            h2,
            h3 {
              font-size: 1.25rem;
              font-weight: normal;
              display: inline-block;
              margin-right: 1rem;
              opacity: 1;
            }

            h1 {
              opacity: 0.8;
            }

            h3 {
              opacity: 0.6;
            }
          `}
        >
          <h2>{content.clients}</h2>
          <h1>{content.title}</h1>
          <h3>{content.year}</h3>
        </div>
        <video
          ref={this.ref}
          data-object-fit="contain"
          css={css`
            position: absolute;
            top: 0
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;

            ${mq[0]} {
              top: 45%;
              transform: translateY(-50%);
            }
          `}
          src={content.source}
          poster={content.poster}
          controls
          autoPlay
        />
      </>
    )
  }
}

class PlayerWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      videoId: 0,
    }

    this.play = this.play.bind(this)
    this.close = this.close.bind(this)
    this.open = this.open.bind(this)
  }

  componentDidMount() {
    import('objectFitPolyfill')
  }

  play(content) {
    this.setState({
      isOpen: true,
      content,
    })
  }

  open() {
    this.setState({
      isOpen: true,
    })
  }

  close() {
    this.setState({
      isOpen: false,
    })
  }

  render() {
    const { render } = this.props
    const { isOpen, content } = this.state
    const { open, close, play } = this
    return (
      <div>
        <Modal isOpen={isOpen} background="#000">
          <Player close={close} content={content} />
        </Modal>
        {render({ open, close, play })}
      </div>
    )
  }
}

export { PlayerWrapper as default, Player }
