import React from 'react'
import { Links, StyledLink } from './links'

const HomeMobileLinks = () => (
  <Links>
    <StyledLink to="/work">Work</StyledLink>
    <StyledLink to="/contact">Contact</StyledLink>
    <StyledLink to="/company">Company</StyledLink>
  </Links>
)

export default HomeMobileLinks
