import React from 'react'
import { Button } from '../button'
import { Link } from 'gatsby'

const ButtonStyledLink = Button.withComponent(Link)

const CtaButton = ({ size }) => (
  <ButtonStyledLink outline size={size || 1.5} to="/contact">
    Get in touch
  </ButtonStyledLink>
)

export default CtaButton
