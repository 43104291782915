import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import { NavWithLogo, MobileNav } from '../components/nav'
import Clients from '../components/company/clients'
import Awards from '../components/company/awards'
import { Sections, Section } from '../components/company/sections'
import CtaButton from '../components/company/cta'
import Footer from '../components/footer'
import { useMedia, getPageName } from '../components/utils'

const About = ({ content, size }) => (
  <div
    css={css`
      font-size: ${({ size }) => size || '1rem'};
      white-space: pre-wrap;
      margin-bottom: 2rem;
      line-height: 1.5;

      small {
        font-size: 0.9em;
        opacity: 0.8;
      }
    `}
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
)

const CompanyPage = ({ data, location }) => {
  const { title, aboutKR, aboutEN } = data.company
  const { pathname } = location
  const isMobile = useMedia(600)
  return (
    <Layout name="Company">
      {isMobile ? (
        <MobileNav title={getPageName(pathname)} />
      ) : (
        <NavWithLogo>
          <div
            css={css`
              width: 100%;
              padding-right: 1.5rem;
              text-align: right;
            `}
          >
            <CtaButton size="1">Get In Touch</CtaButton>
          </div>
        </NavWithLogo>
      )}
      <Sections>
        <Section header={title} width="40%">
          <About content={aboutKR} />
          <About content={aboutEN} size="1.25" />
        </Section>
        <Section header="Clients">
          <Clients />
        </Section>
        <Section header="Awards">
          <Awards />
        </Section>
      </Sections>
      <div
        css={css`
          text-align: center;
          margin-bottom: 10rem;
        `}
      >
        <CtaButton>Get In Touch</CtaButton>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    company {
      title
      aboutKR
      aboutEN
    }
  }
`

export default CompanyPage
