import React from 'react'
import { Icon } from '../../icon'
import { ReactComponent as IconSearch } from '../../../images/svg/ic-search-24.svg'
import { IconContainer, StyledNav, PageTitle } from '../../nav/mobile'
import Modal from '../../modal'
import { Label } from '../../typography'
import { inputStyle } from '../../input'
import styled from '@emotion/styled'
import { WhiteButton } from '../../button'
import { fadeIn } from '../../utils'

const FilterLayout = styled.div`
  position: absolute;
  top: 10vh;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
`

const FilterInputLayout = styled.div`
  margin-bottom: 2rem;
  position: relative;
  animation: ${fadeIn} 0.3s ease-out;
  animation-fill-mode: both;
  animation-delay: ${({ order = 0 }) => order * 0.1}s;

  & > select {
    margin-top: 0.5rem;
  }
`

const MainButtonBar = styled.div`
  margin-top: 5rem;
  position: relative;
`

const SecondaryButtonBar = styled.div`
  margin-top: 1rem;
  text-align: center;

  & > button {
    border: none;
    display: inline-block;
    padding: 0.5rem 2.5rem;
    background: transparent;
    font-size: 1rem;
    color: #fff;
  }

  & > button:first-of-type {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
`

class FilterInput extends React.Component {
  handleSelectChange = e => {
    const { onReset, onChange, items, name } = this.props
    const id = e.target.value - 1
    if (id === -1) {
      onReset(name)
      return
    }
    const { label, value } = items[id]
    onChange({
      name,
      value,
      label,
    })
  }
  render() {
    const { label: filterLabel, name, items, selected, order } = this.props
    return (
      <FilterInputLayout order={order}>
        <Label as="label" htmlFor={`filter-select-${name}`}>
          {filterLabel}
        </Label>
        <select
          id={`filter-select-${name}`}
          css={inputStyle}
          onChange={this.handleSelectChange}
        >
          {items &&
            [{ label: 'Any', value: 'any' }, ...items].map(
              ({ value, label }, i) => (
                <option
                  value={i}
                  key={value}
                  selected={selected ? selected.value === value : false}
                >
                  {label}
                </option>
              )
            )}
        </select>
      </FilterInputLayout>
    )
  }
}

const FilterIcon = ({
  isOpened,
  searchies,
  onReset,
  onChange,
  togglePanel,
  categories,
  clients,
  year,
  onResetAll,
  handleMainButton,
  renderMessage,
}) => {
  console.log({ categories, clients, year })
  return (
    <>
      <Modal isOpen={isOpened} background="#000">
        <StyledNav>
          <PageTitle>Filter</PageTitle>
        </StyledNav>
        <FilterLayout>
          <FilterInput
            order={1}
            label="Industries"
            name="categories"
            items={searchies.categories}
            onChange={onChange}
            onReset={onReset}
            selected={categories}
          />
          <FilterInput
            order={2}
            label="Clients"
            name="clients"
            items={searchies.clients}
            onChange={onChange}
            onReset={onReset}
            selected={clients}
          />
          <FilterInput
            order={3}
            label="Year"
            name="year"
            items={searchies.year}
            onChange={onChange}
            onReset={onReset}
            selected={year}
          />
          <MainButtonBar>
            <WhiteButton size="1" fluid onClick={handleMainButton}>
              Apply
            </WhiteButton>
            <SecondaryButtonBar>
              <button onClick={togglePanel}>Close</button>
              <button onClick={onResetAll}>Reset</button>
            </SecondaryButtonBar>
          </MainButtonBar>
        </FilterLayout>
      </Modal>
      <IconContainer align="right" onClick={togglePanel}>
        <Icon icon={<IconSearch />} />
      </IconContainer>
    </>
  )
}

export { FilterIcon }
