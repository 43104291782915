import React from 'react'
import { css } from '@emotion/core'
import { mq } from '../utils'
import { Icon } from '../icon'
import { ReactComponent as Arrow } from '../../images/svg/arrow.svg'

const NavigationButton = ({ goToPage, totalPage, currentPage, direction }) => {
  const isNext = direction === 'next'
  const destination = isNext ? currentPage + 1 : currentPage - 1
  return (
    <button
      css={css`
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        border: none;
        background: transparent;
        transform: translateX(${isNext ? '-10%' : '10%'});
        transition: 0.3s ease;
        padding: 0;

        &:disabled {
          opacity: 0.2;
        }

        ${mq[0]} {
          margin: 0.75rem;
        }
      `}
      disabled={destination < 0 || destination > totalPage}
      onClick={() => {
        window.scrollTo(0, 0)
        goToPage(destination)
      }}
    >
      <Icon icon={<Arrow />} rotation={isNext ? 0 : 180} />
    </button>
  )
}

const Menu = ({ currentPage, totalPage, goToPage }) => (
  <div
    css={css`
      width: 100%;
      height: 3rem;
      padding: 0.5rem 0;
      position: relative;
      z-index: 100;
      text-align: center;
      opacity: 0.7;
      transition: 0.3s ease;

      &:hover {
        opacity: 1;
        & > button {
          transform: translateX(0);
        }
      }

      & > h4 {
        font-size: 0.8rem;
        display: inline-block;
        width: 5rem;
        margin-bottom: 0;
      }

      ${mq[0]} {
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > button {
          transform: translateX(0);
        }

        & > h4 {
          font-size: 1rem;
        }
      }
    `}
  >
    <NavigationButton
      direction="previous"
      totalPage={totalPage}
      currentPage={currentPage}
      goToPage={goToPage}
    />
    <h4>{`${currentPage + 1} / ${totalPage}`}</h4>
    <NavigationButton
      direction="next"
      totalPage={totalPage}
      currentPage={currentPage}
      goToPage={goToPage}
    />
  </div>
)

class Paginator extends React.Component {
  static defaultProps = {
    itemPerPage: 20,
  }

  state = {
    currentPage: 0,
  }

  getItems = () => {
    const { data, itemPerPage } = this.props
    const { currentPage } = this.state
    const start = itemPerPage * currentPage
    const end = start + itemPerPage
    return data.slice(start, end)
  }

  goToPage = i => {
    const { itemPerPage, data } = this.props
    const totalSize = data.length
    const totalPage = Math.ceil(totalSize / itemPerPage)
    let v = +i
    if (isNaN(v) || v < 0 || v >= totalPage) return

    this.setState({
      currentPage: v,
    })
  }

  render() {
    const { children, data } = this.props
    const itemPerPage = this.props.itemPerPage || 25
    const totalSize = data.length
    const totalPage = Math.ceil(totalSize / itemPerPage)

    const { currentPage } = this.state
    const { goToPage, getItems } = this
    return (
      <div
        key={totalSize}
        css={css`
          position: relative;
          width: 80%;
          max-width: 60rem;
          margin: 7rem auto;

          ${mq[0]} {
            width: 100%;
            margin: 5rem auto;
          }
        `}
      >
        <Menu {...{ currentPage, totalPage, totalSize, goToPage }} />
        <ul
          css={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            > li {
              flex: 0 1 auto;
              width: 50%;
              position: relative;
              padding: 0.5rem 0.25rem;
            }

            ${mq[0]} {
              & > li {
                width: 100%;
              }
            }
          `}
        >
          {getItems().map((video, i) => (
            <li key={video.node.preview.mq}>
              {React.cloneElement(children, { data: video, id: i })}
            </li>
          ))}
        </ul>
        <Menu {...{ currentPage, totalPage, totalSize, goToPage }} />
      </div>
    )
  }
}

export default Paginator
