import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import { mq } from '../utils'

const ClientBox = ({ client }) => {
  const { logo, name } = client
  return (
    <li
      css={css`
        position: relative;
        width: 8.5rem;
        height: 6rem;
        flex: 0 1 25%;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.4;
        font-weight: bolder;
        line-height: 1.25;
        transition: opacity 0.3s ease;
        /* ie 11 bug, flex-basis doesn't account for border-box padding */
        -ms-flex-preferred-size: calc(25% - 2rem);

        &:hover {
          opacity: 0.6;
        }

        ${mq[0]} {
          flex: 0 1 50%;
        }
      `}
    >
      {logo && logo.childImageSharp ? (
        <img
          css={css`
            width: 100%;
            height: auto;
          `}
          src={logo.childImageSharp.fixed.src}
          alt={name}
        />
      ) : (
        <span>{name}</span>
      )}
    </li>
  )
}

const Clients = ({ clients }) => (
  <StaticQuery
    query={graphql`
      query {
        company {
          clients {
            ...ImageAndNameRect
          }
        }
      }
    `}
    render={data => (
      <ul
        css={css`
          margin: 0;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        `}
      >
        {data.company.clients.map((client, i) => (
          <ClientBox client={client} key={i} />
        ))}
      </ul>
    )}
  />
)

export default Clients
