import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const StyledLinkA = styled.a`
  color: #fff;
  text-decoration: none;
  position: relative;
  & + & {
    margin-left: 0.75rem;
  }

  &:before {
    content: ' ';
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%) scaleX(0.5);
    height: 1px;
    bottom: -0.35rem;
    background: white;
    transition: 0.3s ease;
    opacity: 0;
  }

  &:hover {
    &:before {
      transform: translateX(-50%) scaleX(0.8);
      opacity: 1;
    }
  }

  &.active {
    opacity: 0.6;
    &:before {
      opacity: 1;
    }
  }
`

const StyledLink = StyledLinkA.withComponent(props => (
  <Link activeClassName="active" partiallyActive {...props} />
))

const StyledLi = styled.li`
  flex: 1 0 auto;
  text-align: center;
  & + & {
    margin-left: 10%;
  }
`

const Links = ({ children }) => (
  <ul
    css={css`
      position: relative;
      margin: 0 auto;
      width: 80%;
      max-width: 40rem;
      display: flex;
      justify-content: space-evenly;
    `}
  >
    {React.Children.map(children, (child, i) => (
      <StyledLi key={i}>{child}</StyledLi>
    ))}
  </ul>
)

export { Links, StyledLink, StyledLinkA }
