import React from 'react'
import { css } from '@emotion/core'

export default class FadeInImage extends React.Component {
  state = {
    isLoaded: false,
  }
  onLoad = () => {
    this.setState({ isLoaded: true })
  }
  render() {
    const { isLoaded } = this.state
    return (
      <img
        css={css`
          width: 100%;
          object-fit: cover;
          opacity: ${isLoaded ? 1 : 0};
          transition: all 0.3s ease;
        `}
        onLoad={this.onLoad}
        {...this.props}
      />
    )
  }
}
