import React from 'react'
import { css } from '@emotion/core'
import URLSearchParams from '@ungap/url-search-params'

import { isNullorEmpty } from '../../utils'
import { Icon } from '../../icon'
import { ReactComponent as IconSearch } from '../../../../src/images/svg/ic-search-24.svg'
import { FilterBar } from './desktop'
import { FilterIcon } from './mobile'

class Filter extends React.Component {
  constructor(props) {
    super(props)

    // todo: clean this mess
    const { searchies } = this.props
    const params = new URLSearchParams(this.props.searchQuery)
    const categories =
      searchies.categories.find(s => s.value === params.get('categories')) ||
      null
    const clients = searchies.clients.find(
      s => s.value === params.get('clients') || null
    )
    const year =
      searchies.year.find(s => s.value === params.get('year')) || null

    this.state = {
      isOpened: false,
      categories,
      clients,
      year,
    }
  }

  componentDidMount() {
    const { search } = this.props
    const { categories, clients, year } = this.state
    search({ categories, clients, year })
  }

  onChange = ({ name, value, label }) => {
    this.setState(state => ({
      categories: { ...state.categories },
      clients: { ...state.clients },
      year: { ...state.year },
      [name]: {
        value,
        label,
      },
    }))
  }

  onSubmit = () => {
    const { search } = this.props
    const { categories, clients, year } = this.state
    // setUrl({ categories, clients, year })
    search({ categories, clients, year }).then(() => {
      this.setState({
        isOpened: false,
      })
    })
  }

  onReset = name => {
    this.setState(state => ({
      categories: { ...state.categories },
      clients: { ...state.clients },
      year: { ...state.year },
      [name]: null,
    }))
  }

  onResetAll = () => {
    const categories = null
    const clients = null
    const year = null

    this.setState({
      categories,
      clients,
      year,
    })

    const { search } = this.props
    search({ categories, clients, year }).then(() => {
      this.setState({
        isOpened: false,
      })
    })
  }

  togglePanel = () => {
    this.setState(state => ({
      isOpened: !state.isOpened,
    }))
  }

  renderMessage = () => {
    const { isOpened, categories, clients, year } = this.state

    const noCat = isNullorEmpty(categories)
    const noClients = isNullorEmpty(clients)
    const noYear = isNullorEmpty(year)

    if (isOpened) return <span>Apply</span>
    if (noCat && noClients && noYear)
      return (
        <span>
          <Icon size={1.25} icon={<IconSearch />} />
          <span
            css={css`
              margin-left: 0.5rem;
            `}
          >
            {'Search by industries & year'}
          </span>
        </span>
      )
    return (
      <div
        css={css`
          & > span {
            display: inline-block;
            font-weight: lighter;
            opacity: 0.8;
            padding-left: 0.5rem;
            margin-left: 0.5rem;
            border-left: 1px solid #fff;
            font-size: 0.8em;
          }
        `}
      >
        Filter
        {!noCat && <span>{categories.label}</span>}
        {!noClients && <span>{clients.label}</span>}
        {!noYear && <span>{year.label}</span>}
      </div>
    )
  }

  handleMainButton = e => {
    e.preventDefault()
    if (this.state.isOpened) {
      this.onSubmit()
      return
    }

    this.togglePanel()
  }

  render() {
    const { searchies, render } = this.props
    const { categories, clients, year, isOpened } = this.state
    const {
      togglePanel,
      onChange,
      onReset,
      renderMessage,
      handleMainButton,
      onResetAll,
    } = this
    return render({
      isOpened,
      searchies,
      onReset,
      onChange,
      togglePanel,
      categories,
      clients,
      year,
      onResetAll,
      handleMainButton,
      renderMessage,
    })
  }
}

const DesktopFilter = props => (
  <Filter {...props} render={props => <FilterBar {...props} />} />
)

const MobileFilter = props => (
  <Filter {...props} render={props => <FilterIcon {...props} />} />
)

export { DesktopFilter, MobileFilter }
