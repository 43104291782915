import React from 'react'
import Layout from '../components/layout'
import { Nav } from '../components/nav'

const NotFoundPage = () => (
  <Layout>
    <Nav />
    <h1>There's nothing here yet</h1>
  </Layout>
)

export default NotFoundPage
