import { graphql } from 'gatsby'
import React from 'react'
import throttle from 'lodash/throttle'
import { NavWithLogo, MobileNav } from '../components/nav'
import { Global, css } from '@emotion/core'
import { Icon } from '../components/icon'
import { ReactComponent as IconBrowse } from '../../src/images/svg/ic-mode-browse-24.svg'
import { ReactComponent as IconList } from '../../src/images/svg/ic-mode-list-24.svg'

import SearchApi from 'js-worker-search'

import Layout from '../components/layout'
import Player from '../components/work/player'
import Infinite from '../components/work/infinite'
import List from '../components/work/list'
import Cell from '../components/work/cell'
import { DesktopFilter, MobileFilter } from '../components/work/filter'

import URLSearchParams from '@ungap/url-search-params'
import { getPageName } from '../components/utils'

const infiniteConfig = {
  width: 0.25,
  ratio: 0.5625,
  cols: 4,
  gap: 15,
  snap: true,
}

const ModeButton = ({ mode, currentMode, setMode }) => (
  <button
    css={css`
      border: none;
      background: transparent;
      color: #fff;

      &:disabled {
        opacity: 0.4;
      }
    `}
    onClick={() => setMode(mode)}
    disabled={mode === currentMode}
  >
    <Icon icon={mode === 'browse' ? <IconBrowse /> : <IconList />} />
  </button>
)

const ModeToggle = props => (
  <div
    css={css`
      text-align: right;
      margin-right: 1.5rem;
    `}
  >
    <span
      css={css`
        margin-right: 0.5rem;
      `}
    >
      Display
    </span>
    <ModeButton mode="browse" {...props} />
    <ModeButton mode="list" {...props} />
  </div>
)

const Message = ({ message }) => (
  <div
    css={css`
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  >
    <h1>{message}</h1>
  </div>
)

class WorkPage extends React.Component {
  constructor(props) {
    super(props)

    const videos = this.props.data.allVimeoVideo.edges

    const params = new URLSearchParams(this.props.location.search)
    const currentMode = params.get('mode') || 'browse'

    this.state = {
      currentMode,
      results: videos,
      isFiltered: false,
      isSearching: false,
      infiniteWidth: 0.25,
      isMobile: false,
    }

    this.searchApi = new SearchApi()
    this.handleResizeThrottled = throttle(this.handleResize.bind(this), 100, {
      leading: true,
    })

    videos.forEach(({ node: video }) => {
      this.searchApi.indexDocument(
        video.id,
        `${video.categories.toString().replace(',', ' ')} ${video.client ||
          ''} ${video.year || ''}`
      )
    })
  }

  componentDidMount() {
    const isMobile = window.matchMedia('(max-width: 600px)').matches
    this.setState({ isMobile })
    window.addEventListener('resize', this.handleResizeThrottled)
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeThrottled)
  }

  handleResize = () => {
    const width = window.innerWidth
    if (width > 960) this.setState({ infiniteWidth: 0.25 })
    else if (width > 600) this.setState({ infiniteWidth: 0.33 })
    else this.setState({ infiniteWidth: 0.5 })
  }

  setUrl = query => {
    const {
      location: { pathname },
      navigate,
    } = this.props
    const queryString = new URLSearchParams()
    Object.keys(query).map(key => {
      queryString.set(key, query[key])
    })
    navigate(`${pathname}?${queryString}`, {
      replace: false,
    })
  }

  setMode = mode => {
    this.setUrl({ mode })
    this.setState({
      currentMode: mode,
    })
  }

  search = async query => {
    const videos = this.props.data.allVimeoVideo.edges

    const categories = query.categories ? query.categories.value : null
    const year = query.year ? query.year.value : null
    const clients = query.clients ? query.clients.label : null

    if (!categories && !year && !clients) {
      this.setState({
        results: videos,
        isFiltered: false,
      })
      return
    }

    this.setState({
      isSearching: true,
    })

    return this.searchApi
      .search(`${categories || ''} ${year || ''} ${clients || ''}`)
      .then(ids => {
        const results = videos.filter(
          ({ node: video }) => ids.findIndex(id => id === video.id) >= 0
        )
        this.setState({
          results,
          isSearching: false,
          currentMode: 'list',
          isFiltered: true,
        })
      })
  }

  renderResults = () => {
    const {
      results,
      isMobile,
      isSearching,
      currentMode,
      infiniteWidth,
    } = this.state
    if (isSearching) return <Message message={'Searching...'} />
    if (results.length === 0) return <Message message={'No results found'} />
    if (currentMode === 'browse' && !isMobile)
      return (
        <Player
          render={({ play }) => (
            <Infinite
              key={infiniteWidth}
              config={{
                ...infiniteConfig,
                contentSize: results.length,
                width: infiniteWidth,
              }}
              render={props => <Cell data={results} play={play} {...props} />}
            />
          )}
        />
      )
    else
      return (
        <Player
          render={({ play }) => (
            <List data={results} play={play} itemPerPage={isMobile ? 10 : 20} />
          )}
        />
      )
  }

  render() {
    const searchies = this.props.data.allSearchies.edges[0].node
    const { currentMode, isMobile, isFiltered } = this.state
    const {
      location: { pathname },
    } = this.props
    return (
      <Layout name="Work">
        <Global
          styles={css`
            html,
            body {
              overscroll-behavior: none;
            }
          `}
        />
        {isMobile ? (
          <MobileNav title={isFiltered ? 'Results' : getPageName(pathname)}>
            <MobileFilter
              searchies={searchies}
              searchQuery={this.props.location.search}
              search={this.search}
              setUrl={this.setUrl}
            />
          </MobileNav>
        ) : (
          <NavWithLogo>
            <ModeToggle currentMode={currentMode} setMode={this.setMode} />
          </NavWithLogo>
        )}
        {!isMobile && (
          <DesktopFilter
            searchies={searchies}
            searchQuery={this.props.location.search}
            search={this.search}
            setUrl={this.setUrl}
          />
        )}
        {this.renderResults()}
      </Layout>
    )
  }
}

export default WorkPage

export const query = graphql`
  query {
    allSearchies {
      edges {
        node {
          year {
            value
            label
          }
          categories {
            value
            label
          }
          clients {
            value
            label
          }
        }
      }
    }
    allVimeoVideo(
      filter: { display: { eq: true } }
      sort: { fields: [created_time], order: DESC }
    ) {
      edges {
        node {
          id
          duration
          categories
          file {
            hq
            lq
          }
          preview {
            hq
            lq
            mq
          }
          client
          year
          title
        }
      }
    }
  }
`
