import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import { Nav, HomeMobileLinks, SocialMedia } from '../components/nav'
import { Logo, MobileLogo } from '../components/home/logo'
import { useMedia } from '../components/utils'

class NavController extends React.Component {
  state = {
    isActive: false,
  }

  mouseLeaveHandler = () => {
    this.setState({ isActive: false })
  }

  mouseEnterHandler = () => {
    this.setState({ isActive: true })
  }

  componentDidMount() {
    // if devide has a touch screen, just show the menu
    if ('ontouchstart' in window) {
      this.setState({ isActive: true })
      return
    }
    const checkIfMouseInWindow = e => {
      const pX = e.pageX || e.clientX
      const pY = e.pageY || e.clientY
      if (pX < 0 || pY < 0) this.setState({ isActive: false })
      else this.setState({ isActive: true })
      window.removeEventListener('mousemove', checkIfMouseInWindow)
    }

    window.addEventListener('mousemove', checkIfMouseInWindow)

    window.addEventListener('mouseleave', this.mouseLeaveHandler)
    window.addEventListener('mouseenter', this.mouseEnterHandler)
    document.body.addEventListener('mouseleave', this.mouseLeaveHandler)
    document.body.addEventListener('mouseenter', this.mouseEnterHandler)
  }

  componentWillUnmount() {
    if ('ontouchstart' in window) return
    window.removeEventListener('mouseleave', this.mouseLeaveHandler)
    window.removeEventListener('mouseenter', this.mouseEnterHandler)
    document.body.removeEventListener('mouseleave', this.mouseLeaveHandler)
    document.body.removeEventListener('mouseenter', this.mouseEnterHandler)
  }

  render() {
    const { isActive } = this.state
    return (
      <div
        css={css`
          position: fixed;
          width: 100%;
          height: 7rem;
          opacity: ${isActive ? 1 : 0};
          transform: scale(${isActive ? '1' : '1.1'});
          transition: 0.5s ease;
          z-index: 1000;
        `}
      >
        <Nav />
      </div>
    )
  }
}

const TopMenu = styled.nav`
  position: absolute;
  top: 2rem;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
`

const BottomMenu = styled.div`
  position: absolute;
  bottom: 2rem;
  font-size: 1.5rem;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
`

const IndexPage = ({ data }) => {
  const isMobile = useMedia(600)
  return (
    <Layout>
      {isMobile ? (
        <>
          <TopMenu>
            <SocialMedia size={2} />
          </TopMenu>
          <MobileLogo />
          <BottomMenu>
            <HomeMobileLinks />
          </BottomMenu>
        </>
      ) : (
        <>
          <NavController />
          <Logo />
        </>
      )}
    </Layout>
  )
}

export default IndexPage
