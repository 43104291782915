import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Paginator from './paginator'
import FadeInImage from '../fadeInImage'
import { secondsToMinute, fadeIn, mq } from '../utils'

const InfoBar = styled.div`
  position: relative;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;

  ${mq[0]} {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 1rem;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  }
`

const InfoLeft = styled.div`
  flex: 0 1 auto;
  & > * {
    font-size: 0.8rem;
    font-weight: normal;
    display: inline-block;
    margin: 0 0.25rem 0 0;
    line-height: 1;
  }

  & > h1 {
    opacity: 0.8;
  }
  & > h3 {
    opacity: 0.6;
  }

  ${mq[0]} {
    & > * {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
`

const Duration = styled.div`
  flex: 0 1 auto;
  font-size: 0.8rem;
`

const Cell = ({ data, play, id }) => {
  const { duration, preview, file, client, title, year } = data.node
  const poster = preview.mq
  const source = file.hq
  return (
    <div>
      <div
        tabIndex="0"
        role="button"
        onClick={() => {
          play({ source, year, title, poster })
        }}
        css={css`
          color: white;
          position: relative;
          display: block;
          width: 100%;
          padding-bottom: 56.25%;
          background: rgba(255, 255, 255, 0.2) center/cover no-repeat;
          overflow: hidden;
          transition: transform 0.3s ease;
          animation: ${fadeIn} 0.3s ease-out;
          animation-fill-mode: both;
          animation-delay: ${id / 10}s;

          &:hover {
            transform: scale(1);
            outline: 1px solid rgba(255, 255, 255, 0.6);
          }

          &:hover .image {
            transform: scale(1.05);
          }

          &:hover > .play {
            transform: scale(1);
            opacity: 1;
          }
        `}
      >
        <div
          className="play"
          css={css`
            width: 3rem;
            height: 3rem;
            background: url(/play.svg) center/cover no-repeat;
            border-radius: 1.5rem;
            transform: scale(1.1);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
            opacity: 0;
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;
            z-index: 200;
            transition: all 0.3s ease;
          `}
        />
        <div
          className="image"
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            transform: scale(1);
            top: 0;
            left: 0;
            z-index: 100;
            transition: transform 0.3s ease;
          `}
        >
          <FadeInImage
            key={poster}
            src={poster}
            alt={`${client} - ${title} - ${year}`}
          />
        </div>
      </div>
      <InfoBar>
        <InfoLeft>
          <h2>{client}</h2>
          <h1>{title}</h1>
          <h3>{year}</h3>
        </InfoLeft>
        <Duration>{secondsToMinute(duration)}</Duration>
      </InfoBar>
    </div>
  )
}

const List = ({ data, play, itemPerPage }) => (
  <Paginator itemPerPage={itemPerPage} data={data}>
    <Cell play={play} />
  </Paginator>
)

export default List
